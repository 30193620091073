
































import {
  defineComponent,
  computed,
  onBeforeMount,
  watch,
} from "@vue/composition-api";
import { Dog, DogUpdateBody, Applicant, User } from "@/store";
import {
  useStore,
  useDogService,
  useRouter,
  useApplicantService,
  useUserService,
} from "@/composables/providers";
import { DogActions, DogGetters } from "@/store/dog";
import DogForm from "@/components/forms/Dog.vue";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import { SessionGetters } from "@/store/session";
import { UserActions, UserGetters } from "@/store/user";

export default defineComponent({
  name: "DogsUpdate",
  components: {
    DogForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const service = useDogService();
    const applicantService = useApplicantService();
    const userService = useUserService();

    const id = computed<string>(() => router.currentRoute.params["id"] || "");

    async function submit(body: DogUpdateBody) {
      await store.dispatch(DogActions.UPDATE, {
        id: id.value,
        body,
        service,
      });
      await router.push({ name: "DogDetail", params: { id: id.value } });
    }

    const dogsLoading = computed<boolean>(
      () => store.getters[DogGetters.LOADING]
    );
    const applicantsLoading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const usersLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => applicantsLoading.value || dogsLoading.value || usersLoading.value
    );

    const applicants = computed<Applicant[]>(
      () => store.getters[ApplicantGetters.APPLICANTS]
    );
    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );

    const users = computed<User>(() => store.getters[UserGetters.USERS]);

    const dogTrainers = computed<User[]>(
      () => store.getters[UserGetters.DOG_TRAINERS]
    );
    const isAdmin = computed<boolean>(
      () => store.getters[SessionGetters.IS_SITE_ADMIN_OR_ORGANIZATION_ADMIN]
    );

    const isStaffOrHigher = computed<boolean>(
      () => store.getters[SessionGetters.IS_STAFF_OR_HIGHER]
    );
    const selected = computed<Dog>(() => store.getters[DogGetters.SELECTED]);

    async function cancelEdit() {
      await router.push({
        name: "DogDetail",
        params: { id: ((selected.value || {}).id || "").toString() },
      });
    }

    onBeforeMount(async () => {
      Promise.all([
        await store.dispatch(DogActions.SELECT, {
          id: id.value,
          service,
        }),
        await store.dispatch(ApplicantActions.LIST, {
          service: applicantService,
        }),
        await store.dispatch(UserActions.LIST, { service: userService }),
      ]);
    });

    watch(id, async (newVal) => {
      await store.dispatch(DogActions.SELECT, {
        id: newVal,
        service,
      });
    });

    return {
      submit,
      store,
      service,
      loading,
      router,
      id,
      selected,
      cancelEdit,
      applicants,
      currentUser,
      users,
      isAdmin,
      isStaffOrHigher,
      dogTrainers,
    };
  },
});
